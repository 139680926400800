import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

const PrivacyPolicy = () => {

    return (
        <Container id="main" role="main">
            <div className="{window.location.hostname}-policy__container">
                <div className="{window.location.hostname}-policy__title">
                    <h1>Privacy Policy </h1>
                </div>

                <div className="{window.location.hostname}-policy__body">
                    <div className="rte">

                        <p style={{textalign: 'center'}}>&nbsp;</p>
                        <p style={{textalign: 'center'}}>This Privacy Policy describes how {window.location.hostname}  (the
                            "<strong>Site</strong>", "<strong>we</strong>", "<strong>us</strong>", or
                            "<strong>our</strong>") collects, uses, and discloses your personal information when you
                            visit, use our services, or make a purchase from {window.location.hostname} (the
                            "<strong>Site</strong>") or otherwise communicate with us (collectively, the
                            "<strong>Services</strong>"). For purposes of this Privacy Policy, "<strong>you</strong>"
                            and "<strong>your</strong>" means you as the user of the Services, whether you are a
                            customer, website visitor, or another individual whose information we have collected
                            pursuant to this Privacy Policy.</p>
                        <p style={{textalign: 'center'}}>Please read this Privacy Policy carefully. By using and
                            accessing any of the Services, you agree to the collection, use, and disclosure of your
                            information as described in this Privacy Policy. If you do not agree to this Privacy Policy,
                            please do not use or access any of the Services.</p>
                        <h2 style={{textalign: 'center'}}>Changes to This Privacy Policy</h2>
                        <p style={{textalign: 'center'}}>We may update this Privacy Policy from time to time, including
                            to reflect changes to our practices or for other operational, legal, or regulatory reasons.
                            We will post the revised Privacy Policy on the Site, update the "Last updated" date and take
                            any other steps required by applicable law.</p>
                        <h2 style={{textalign: 'center'}}>How We Collect and Use Your Personal Information</h2>
                        <p style={{textalign: 'center'}}>To provide the Services, we collect and have collected over the
                            past 12 months personal information about you from a variety of sources, as set out below.
                            The information that we collect and use varies depending on how you interact with us.</p>
                        <p style={{textalign: 'center'}}>In addition to the specific uses set out below, we may use
                            information we collect about you to communicate with you, provide the Services, comply with
                            any applicable legal obligations, enforce any applicable terms of service, and to protect or
                            defend the Services, our rights, and the rights of our users or others.</p>
                        <h3 style={{textalign: 'center'}}>What Personal Information We Collect</h3>
                        <p style={{textalign: 'center'}}>The types of personal information we obtain about you depends
                            on how you interact with our Site and use our Services. When we use the term "personal
                            information", we are referring to information that identifies, relates to, describes or can
                            be associated with you. The following sections describe the categories and specific types of
                            personal information we collect.</p>
                        <h3 style={{textalign: 'center'}}>Information We Collect Directly from You</h3>
                        <p style={{textalign: 'center'}}>Information that you directly submit to us through our Services
                            may include:</p>
                        <ul style={{textalign: 'center'}}>
                            <li><strong>Basic contact details</strong> including your name, address, phone number,
                                email.
                            </li>
                            <li><strong>Order information</strong> including your name, billing address, shipping
                                address, payment confirmation, email address, phone number.
                            </li>
                            <li><strong>Account information</strong> including your username, password, security
                                questions.
                            </li>
                            <li><strong>Shopping information</strong> including the items you view, put in your cart or
                                add to your wishlist.
                            </li>
                            <li><strong>Customer support information</strong> including the information you choose to
                                include in communications with us, for example, when sending a message through the
                                Services.
                            </li>
                        </ul>
                        <p style={{textalign: 'center'}}>Some features of the Services may require you to directly
                            provide us with certain information about yourself. You may elect not to provide this
                            information, but doing so may prevent you from using or accessing these features.</p>
                        <h3 style={{textalign: 'center'}}>Information We Collect through Cookies</h3>
                        <p style={{textalign: 'center'}}>We also automatically collect certain information about your
                            interaction with the Services ("<strong>Usage Data</strong>"). To do this, we may use
                            cookies, pixels and similar technologies ("<strong>Cookies</strong>"). Usage Data may
                            include information about how you access and use our Site and your account, including device
                            information, browser information, information about your network connection, your IP address
                            and other information regarding your interaction with the Services.</p>
                        <h3 style={{textalign: 'center'}}>Information We Obtain from Third Parties</h3>
                        <p style={{textalign: 'center'}}>Finally, we may obtain information about you from third
                            parties, including from vendors and service providers who may collect information on our
                            behalf, such as:</p>
                        <ul style={{textalign: 'center'}}>
                            <li>Companies who support our Site and Services, such as {window.location.hostname}.</li>
                            <li>Our payment processors, who collect payment information (e.g., bank account, credit or
                                debit card information, billing address) to process your payment in order to fulfill
                                your orders and provide you with products or services you have requested, in order to
                                perform our contract with you.
                            </li>
                            <li>When you visit our Site, open or click on emails we send you, or interact with our
                                Services or advertisements, we, or third parties we work with, may automatically collect
                                certain information using online tracking technologies such as pixels, web beacons,
                                software developer kits, third-party libraries, and cookies.
                            </li>
                        </ul>
                        <p style={{textalign: 'center'}}>Any information we obtain from third parties will be treated in
                            accordance with this Privacy Policy. We are not responsible or liable for the accuracy of
                            the information provided to us by third parties and are not responsible for any third
                            party's policies or practices. For more information, see the section below, <em>Third Party
                                Websites and Links</em>.</p>
                        <h3 style={{textalign: 'center'}}>How We Use Your Personal Information</h3>
                        <ul style={{textalign: 'center'}}>
                            <li><strong>Providing Products and Services.</strong> We use your personal information to
                                provide you with the Services in order to perform our contract with you, including to
                                process your payments, fulfill your orders, to send notifications to you related to you
                                account, purchases, returns, exchanges or other transactions, to create, maintain and
                                otherwise manage your account, to arrange for shipping, facilitate any returns and
                                exchanges and to enable you to post reviews.
                            </li>
                            <li><strong>Marketing and Advertising.</strong> We use your personal information for
                                marketing and promotional purposes, such as to send marketing, advertising and
                                promotional communications by email, text message or postal mail, and to show you
                                advertisements for products or services. This may include using your personal
                                information to better tailor the Services and advertising on our Site and other
                                websites.
                            </li>
                            <li><strong>Security and Fraud Prevention.</strong> We use your personal information to
                                detect, investigate or take action regarding possible fraudulent, illegal or malicious
                                activity. If you choose to use the Services and register an account, you are responsible
                                for keeping your account credentials safe. We highly recommend that you do not share
                                your username, password, or other access details with anyone else. If you believe your
                                account has been compromised, please contact us immediately.
                            </li>
                            <li><strong>Communicating with you.</strong> We use your personal information to provide you
                                with customer support and improve our Services. This is in our legitimate interests in
                                order to be responsive to you, to provide effective services to you, and to maintain our
                                business relationship with you.
                            </li>
                        </ul>
                        <h2 style={{textalign: 'center'}}>Cookies</h2>
                        <p style={{textalign: 'center'}}>Like many websites, we use Cookies on our Site. For specific
                            information about the Cookies that we use related to powering our store
                            with {window.location.hostname}, see <a
                                href="https://www.{window.location.hostname}.com/legal/cookies">https://www.{window.location.hostname}.com/legal/cookies</a>.
                            We use Cookies to power and improve our Site and our Services (including to remember your
                            actions and preferences), to run analytics and better understand user interaction with the
                            Services (in our legitimate interests to administer, improve and optimize the Services). We
                            may also permit third parties and services providers to use Cookies on our Site to better
                            tailor the services, products and advertising on our Site and other websites.</p>
                        <p style={{textalign: 'center'}}>Most browsers automatically accept Cookies by default, but you
                            can choose to set your browser to remove or reject Cookies through your browser controls.
                            Please keep in mind that removing or blocking Cookies can negatively impact your user
                            experience and may cause some of the Services, including certain features and general
                            functionality, to work incorrectly or no longer be available. Additionally, blocking Cookies
                            may not completely prevent how we share information with third parties such as our
                            advertising partners.</p>
                        <h2 style={{textalign: 'center'}}>How We Disclose Personal Information</h2>
                        <p style={{textalign: 'center'}}>In certain circumstances, we may disclose your personal
                            information to third parties for legitimate purposes subject to this Privacy Policy. Such
                            circumstances may include:</p>
                        <ul style={{textalign: 'center'}}>
                            <li>With vendors or other third parties who perform services on our behalf (e.g., IT
                                management, payment processing, data analytics, customer support, cloud storage,
                                fulfillment and shipping).
                            </li>
                            <li>With business and marketing partners, including {window.location.hostname}, to provide
                                services and advertise to you.&nbsp;Our business and marketing partners will use your
                                information in accordance with their own privacy notices.
                            </li>
                            <li>When you direct, request us or otherwise consent to our disclosure of certain
                                information to third parties, such as to ship you products or through your use of social
                                media widgets or login integrations, with your consent.
                            </li>
                            <li>With our affiliates or otherwise within our corporate group, in our legitimate interests
                                to run a successful business.
                            </li>
                            <li>In connection with a business transaction such as a merger or bankruptcy, to comply with
                                any applicable legal obligations (including to respond to subpoenas, search warrants and
                                similar requests), to enforce any applicable terms of service, and to protect or defend
                                the Services, our rights, and the rights of our users or others.
                            </li>
                        </ul>
                        <p style={{textalign: 'center'}}>We have, in the past 12 months disclosed the following
                            categories of personal information and sensitive personal information (denoted by *) about
                            users for the purposes set out above in <em>"How we Collect and Use your Personal
                                Information"</em> and <em>"How we Disclose Personal Information"</em>:</p>
                        <table style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tbody>
                            <tr>
                                <th>Category</th>
                                <th>Categories of Recipients</th>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>Identifiers such as basic contact details and certain order and account
                                            information
                                        </li>
                                        <li>Commercial information such as order information, shopping information and
                                            customer support information
                                        </li>
                                        <li>Internet or other similar network activity, such as Usage Data</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Vendors and third parties who perform services on our behalf (such as
                                            Internet service providers, payment processors, fulfillment partners,
                                            customer support partners and data analytics providers)
                                        </li>
                                        <li>Business and marketing partners</li>
                                        <li>Affiliates</li>
                                    </ul>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p style={{textalign: 'center'}}>We do not use or disclose sensitive personal information for
                            the purposes of inferring characteristics about you.</p>
                        <p style={{textalign: 'center'}}>User Generated Content</p>
                        <p style={{textalign: 'center'}}>The Services may enable you to post product reviews and other
                            user-generated content. If you choose to submit user generated content to any public area of
                            the Services, this content will be public and accessible by anyone.</p>
                        <p style={{textalign: 'center'}}>We do not control who will have access to the information that
                            you choose to make available to others, and cannot ensure that parties who have access to
                            such information will respect your privacy or keep it secure. We are not responsible for the
                            privacy or security of any information that you make publicly available, or for the
                            accuracy, use or misuse of any information that you disclose or receive from third
                            parties.</p>
                        <h2 style={{textalign: 'center'}}>Third Party Websites and Links</h2>
                        <p style={{textalign: 'center'}}>Our Site may provide links to websites or other online
                            platforms operated by third parties. If you follow links to sites not affiliated or
                            controlled by us, you should review their privacy and security policies and other terms and
                            conditions. We do not guarantee and are not responsible for the privacy or security of such
                            sites, including the accuracy, completeness, or reliability of information found on these
                            sites. Information you provide on public or semi-public venues, including information you
                            share on third-party social networking platforms may also be viewable by other users of the
                            Services and/or users of those third-party platforms without limitation as to its use by us
                            or by a third party. Our inclusion of such links does not, by itself, imply any endorsement
                            of the content on such platforms or of their owners or operators, except as disclosed on the
                            Services.</p>
                        <h2 style={{textalign: 'center'}}>Children’s Data</h2>
                        <p style={{textalign: 'center'}}>The Services are not intended to be used by children, and we do
                            not knowingly collect any personal information about children. If you are the parent or
                            guardian of a child who has provided us with their personal information, you may contact us
                            using the contact details set out below to request that it be deleted.</p>
                        <p style={{textalign: 'center'}}>As of the Effective Date of this Privacy Policy, we do not have
                            actual knowledge that we “share” or “sell” (as those terms are defined in applicable law)
                            personal information of individuals under 16 years of age.</p>
                        <h2 style={{textalign: 'center'}}>Security and Retention of Your Information</h2>
                        <p style={{textalign: 'center'}}>Please be aware that no security measures are perfect or
                            impenetrable, and we cannot guarantee “perfect security.” In addition, any information you
                            send to us may not be secure while in transit. We recommend that you do not use unsecure
                            channels to communicate sensitive or confidential information to us.</p>
                        <p style={{textalign: 'center'}}>How long we retain your personal information depends on
                            different factors, such as whether we need the information to maintain your account, to
                            provide the Services, comply with legal obligations, resolve disputes or enforce other
                            applicable contracts and policies.</p>
                        <h2 style={{textalign: 'center'}}>Your Rights and Choices</h2>
                        <p style={{textalign: 'center'}}>Depending on where you live, you may have some or all of the
                            rights listed below in relation to your personal information. However, these rights are not
                            absolute, may apply only in certain circumstances and, in certain cases, we may decline your
                            request as permitted by law.</p>
                        <ul style={{textalign: 'center'}}>
                            <li><strong>Right to Access / Know.</strong> You may have a right to request access to
                                personal information that we hold about you, including details relating to the ways in
                                which we use and share your information.
                            </li>
                            <li><strong>Right to Delete.</strong> You may have a right to request that we delete
                                personal information we maintain about you.
                            </li>
                            <li><strong>Right to Correct.</strong> You may have a right to request that we correct
                                inaccurate personal information we maintain about you.
                            </li>
                            <li><strong>Right of Portability.</strong> You may have a right to receive a copy of the
                                personal information we hold about you and to request that we transfer it to a third
                                party, in certain circumstances and with certain exceptions.
                            </li>
                        </ul>
                        <ul style={{textalign: 'center'}}>
                            <li><strong>Restriction of Processing:</strong> You may have the right to ask us to stop or
                                restrict our processing of personal information.
                            </li>
                            <li><strong>Withdrawal of Consent:</strong> Where we rely on consent to process your
                                personal information, you may have the right to withdraw this consent.
                            </li>
                            <li><strong>Appeal:</strong> You may have a right to appeal our decision if we decline to
                                process your request. You can do so by replying directly to our denial.
                            </li>
                            <li><strong>Managing Communication Preferences:</strong> We may send you promotional emails,
                                and you may opt out of receiving these at any time by using the unsubscribe option
                                displayed in our emails to you. If you opt out, we may still send you non-promotional
                                emails, such as those about your account or orders that you have made.
                            </li>
                        </ul>
                        <p style={{textalign: 'center'}}>You may exercise any of these rights where indicated on our
                            Site or by contacting us using the contact details provided below.</p>
                        <p style={{textalign: 'center'}}>We will not discriminate against you for exercising any of
                            these rights. We may need to collect information from you to verify your identity, such as
                            your email address or account information, before providing a substantive response to the
                            request. In accordance with applicable laws, You may designate an authorized agent to make
                            requests on your behalf to exercise your rights. Before accepting such a request from an
                            agent, we will require that the agent provide proof you have authorized them to act on your
                            behalf, and we may need you to verify your identity directly with us. We will respond to
                            your request in a timely manner as required under applicable law.</p>
                        <p style={{textalign: 'center'}}>&nbsp;</p>
                        <h2 style={{textalign: 'center'}}>Complaints</h2>
                        <p style={{textalign: 'center'}}>If you have complaints about how we process your personal
                            information, please contact us using the contact details provided below. If you are not
                            satisfied with our response to your complaint, depending on where you live you may have the
                            right to appeal our decision by contacting us using the contact details set out below, or
                            lodge your complaint with your local data protection authority.</p>
                        <h2 style={{textalign: 'center'}}>International Users</h2>
                        <p style={{textalign: 'center'}}>Please note that we may transfer, store and process your
                            personal information outside the country you live in, including the United States. Your
                            personal information is also processed by staff and third party service providers and
                            partners in these countries.</p>
                        <p style={{textalign: 'center'}}>If we transfer your personal information out of Europe, we will
                            rely on recognized transfer mechanisms like the European Commission's Standard Contractual
                            Clauses, or any equivalent contracts issued by the relevant competent authority of the UK,
                            as relevant, unless the data transfer is to a country that has been determined to provide an
                            adequate level of protection.</p>
                        <h2 style={{textalign: 'center'}}>Contact</h2>
                        <p style={{textalign: 'center'}}>Should you have any questions about our privacy practices or
                            this Privacy Policy, or if you would like to exercise any of the rights available to you
                            email us at support@{window.location.hostname} or contact us at India.</p>

                    </div>
                </div>
                <div className="text-center">
                    <h3 style={{fontSize: '18px', fontWeight: 700, color: '#727272', textAlign: 'center'}}>Get Our
                        Newsletter</h3>
                    <div className="Footer__Content Rte">
                        Subscribe to receive update, access to exclusive deals, and more.
                    </div>
                    <Form method="post" action="/contact#footer-newsletter" id="footer-newsletter"
                          accept-charset="UTF-8" className="my-3">
                        <input type="hidden" name="form_type" value="customer"/>
                        <input type="hidden" name="utf8" value="✓"/>
                        <input type="hidden" name="contact[tags]" value="newsletter"/>
                        <Form.Control size="lg" type="email" placeholder="Enter email"/>
                        <Button type="button" variant="dark" className="mt-3" style={{
                            background: "var(--them-color)",
                            borderColor: "var(--them-color)",
                        }}>Subscribe</Button>
                    </Form>
                </div>
            </div>
        </Container>
    );
}

export default PrivacyPolicy;
